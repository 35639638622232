<i18n>
ru:
  buttonText: Получить пароль
  hint: На указанный номер придет sms с паролем для входа в личный кабинет
  title: Изменить номер
  subtitle: Введите ваш новый номер телефона
ua:
  buttonText: Отримати пароль
  hint: На вказаний номер прийде sms з паролем для входу в Особистий кабінет
  title: Змінити номер
  subtitle: Введіть ваш новий номер телефону
us:
  buttonText: Get a password
  hint: >-
    An sms with a password to log in to your personal account will be sent to
    the specified number
  title: Change phone number
  subtitle: Enter your new phone number
</i18n>

<template>
  <div class="v-popup-head v-title">
    <div
      class="v-change-phone-popup-title"
      v-html="translate('changePhone.title')"
    />
  </div>
  <div
    class="v-change-phone-popup-subtitle"
    v-html="translate('changePhone.subtitle')"
  />
  <common-phone-input
    focus-on-mount
    :disabled="!isVerifyContactStage"
    :label="translate('profile.phone')"
    :validator-form="validatorForm"
    v-model:phone="fields.phone"
  />
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <common-captcha v-if="isVerifyContactStage" />
  </transition>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="!isVerifyContactStage"
      class="v-change-phone-popup-block"
    >
      <div
        class="v-change-phone-popup-label"
        v-html="translate('accountManagement.enterSentCode')"
      />
      <input
        class="v-form-control v-change-phone-popup-input"
        enterkeyhint="done"
        v-model="fields.code"
        @keyup.enter="send"
      />
    </div>
  </transition>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="changeError"
      class="v-mb-sm v-error-color"
      v-html="changeError"
    />
  </transition>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="!changeError"
      class="v-change-phone-popup-hint"
      v-html="translate('changePhone.hint')"
    />
  </transition>

  <div class="v-change-phone-popup-button">
    <arora-button
      :disabled="isVerifyContactStage ? !fields.phone : !fields.code"
      :label="translate(isVerifyContactStage ? 'changePhone.buttonText' : 'changePhone.title')"
      @click="send"
    />
  </div>
</template>

<script setup lang="ts">
import { AuthVariant } from '~api/consts'

import type { ResetPayload } from '~types/accountStore'

import { useValidationStore } from '@arora/common'
import { getActivePinia } from 'pinia'

const restaurantStore = useRestaurantStore()
const accountStore = useAccountStore()
const validationStore = useValidationStore(getActivePinia())

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
const { handleAuthError } = useAccount()

const phoneForLogin = useCookie('phoneForLogin')

const changeError = ref<string | null>(null)
const fields = ref<ResetPayload>({
  phone: null as string | null,
  code: null as string | null,
  country: appConfig.VueSettingsPreRun.SelectedLanguage,
  codeMaxLength: 6,
  recaptcha: null as string | null,
  smartcaptcha: null as string | null,
  passedV3: true,
  ignorePhoneCheck: true
})
const isVerifyContactStage = ref<boolean>(true)

const validatorForm = 'changePhone'

async function send(): Promise<void> {
  if (!validationStore.formPassedCheck(validatorForm)) {
    return
  }

  changeError.value = null
  if (isVerifyContactStage.value) {
    fields.value = await restaurantStore.validateCaptcha(fields.value)

    const error = await accountStore.verifyMainContact(fields.value, AuthVariant.Default)
    if (error.code === 0) isVerifyContactStage.value = false
    else changeError.value = await handleAuthError(error)
  } else {
    const error = await accountStore.changeMainContact(fields.value)

    if (error.code === 0) {
      phoneForLogin.value = fields.value?.phone ?? null

      await accountStore.logout()
      await navigateTo(`${appConfig.VueSettingsPreRun.Links.MainLink}?showLoginPopup=true`)
    } else changeError.value = await handleAuthError(error)
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-change-phone-popup-title {
  font-weight: 700;
  font-size: variables.$TextSizeH3;
}

.v-change-phone-popup-subtitle {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1.1rem;
}

.v-change-phone-popup-hint {
  color: variables.$BodyTextColorLight;
  margin-bottom: 10px;
}

.v-change-phone-popup-error {
  color: variables.$ErrorColor;
  margin-bottom: 10px;
}

.v-change-phone-popup-button {
  display: flex;
  justify-content: center;
}

.v-change-phone-popup-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.1rem;

  .v-change-phone-popup-label {
    flex: 0 0 35%;
    max-width: 35%;
  }

  .v-change-phone-popup-input {
    flex: 0 0 65%;
    max-width: 65%;
  }
}
</style>
